


















































































import BaseLayout from "@/components/BaseLayout.vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import RestValuationRequest, {
  RequestType,
  ValuationRequestForm,
} from "@/rest/RestValuationRequest"
import ValuationRequest from "@/models/ValuationRequest"

@Component({
  components: { BaseLayout },
})
export default class CustomerDetail extends BaseLayout {
  @Prop() readonly requesteTypes!: RequestType[]
  @Prop() readonly id!: number
  @Prop() readonly valuationRequest!: ValuationRequest

  private valuationRequestForm: Partial<ValuationRequestForm> = {}
  private selectedRequestedType: Partial<RequestType> = {}
  private bntSaveLoading = false

  mounted() {
    this.valuationRequestForm.customerName = this.valuationRequest.customerName
    this.valuationRequestForm.customerContact = this.valuationRequest.customerContact
    this.valuationRequestForm.priceRequested = this.valuationRequest.priceRequested
    this.valuationRequestForm.bankReferenceId = this.valuationRequest.bankReferenceId
    this.valuationRequestForm.code = this.valuationRequest.code
    this.selectedRequestedType =
      this.requesteTypes.find((item) => item.id == this.valuationRequest.requestType) || {}
  }

  @Watch("valuationRequest")
  onValuationRequest(newVal: ValuationRequest) {
    this.valuationRequestForm.customerName = newVal.customerName
    this.valuationRequestForm.customerContact = newVal.customerContact
    this.valuationRequestForm.priceRequested = newVal.priceRequested
    this.valuationRequestForm.bankReferenceId = newVal.bankReferenceId
    this.valuationRequestForm.code = newVal.code
    this.selectedRequestedType =
      this.requesteTypes.find((item) => item.id == newVal.requestType) || {}
  }

  @Watch("requesteTypes")
  onRequestTypeChange(newVal: RequestType[]) {
    this.selectedRequestedType =
      newVal.find((item) => item.id == this.valuationRequest.requestType) || {}
  }

  async onSaveClick() {
    this.bntSaveLoading = true
    try {
      this.valuationRequestForm.id = this.id
      this.valuationRequestForm.requestType = this.selectedRequestedType.id
      await new RestValuationRequest().updateCustomer(this.valuationRequestForm)
      this.toastSuccessMsg("Customer Updated Successfully")
      // this.$emit("onSaveSuccess")
    } catch (e) {
      this.toastErrorMsg(e)
    }
    this.bntSaveLoading = false
  }
}
