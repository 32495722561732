





















































































































































































import BaseLayout from "@/components/BaseLayout.vue"
import { Component, Prop } from "vue-property-decorator"
import RestValuationRequest, {
  ValuationRequestForm,
} from "@/rest/RestValuationRequest"
import ValuationRequest from "@/models/ValuationRequest"
import State from "@/models/State"
import PropertyType from "@/models/PropertyType"
import Reference from "@/models/Reference"

@Component({
  components: { BaseLayout },
})
export default class PropertyDetail extends BaseLayout {
  @Prop() readonly id!: number
  @Prop() readonly valuationRequest!: ValuationRequest
  @Prop() readonly states!: State[]
  @Prop() readonly propertyType!: PropertyType[]
  private storeies: Reference[] = [
    { code: "SINGLE_LEVEL", id: 1, desc: "Single Storey" },
    { code: "SINGLE_LEVEL_5", id: 51, desc: "One and a half Storey" },
    { code: "TWO_LEVEL", id: 2, desc: "Two Storey" },
    { code: "TWO_LEVEL_5", id: 52, desc: "Two and a half Storey" },
    { code: "THREE_LEVEL", id: 3, desc: "Three Storey" },
    { code: "THREE_LEVEL_5", id: 53, desc: "Three and a half Storey" },
    { code: "FOUR_LEVEL", id: 4, desc: "Four Storey" },
    { code: "FOUR_LEVEL_5", id: 54, desc: "Four and a half Storey" },
    { code: "FIVE_LEVEL", id: 5, desc: "Five Storey" },
    { code: "FIVE_LEVEL_5", id: 55, desc: "Five and a half Storey" },
    { code: "SIX_LEVEL", id: 6, desc: "Six Storey" },
    { code: "SIX_LEVEL_5", id: 56, desc: "Six and a half Storey" },
    { code: "MULTIPLE_LEVEL", id: 7, desc: "Multiple Storey" },
  ]
  private propertyPositions: Reference[] = [
    { code: "POSITION_INTERMEDIATE, ", id: 1, desc: "Intermediate" },
    { code: "POSITION_CORNER, ", id: 2, desc: "Corner" },
    {
      code: "POSITION_INTERMEDIATE_CORNER, ",
      id: 3,
      desc: "Intermediate Corner",
    },
    { code: "POSITION_END_LOT, ", id: 4, desc: "End Lot" },
  ]
  private tenures: Reference[] = [
    { code: "FREEHOLD", id: 1, desc: "Freehold" },
    { code: "LEASEHOLD", id: 2, desc: "Leasehold" },
  ]

  private bumiStatus: Reference[] = [
    { code: "BUMI", id: 1, desc: "Bumi" },
    { code: "NON_BUMI", id: 2, desc: "non-Bumi" },
  ]

  private valuationRequestForm: Partial<ValuationRequestForm> = {}
  private selectedState: Partial<State> = {}
  private selectedPropertyType: Partial<PropertyType> = {}
  private selectedStorey: Partial<Reference> = {}
  private selectedPropertyPosition: Partial<Reference> = {}
  private selectedTenure: Partial<Reference> = {}
  private selectedBumiStatus: Partial<Reference> = {}

  mounted() {
    this.updateValue()
  }

  updateValue() {
    this.valuationRequestForm = this.valuationRequest
    this.selectedState =
      this.states.find(
        (item) => (item.id = this.valuationRequest.stateTypeId)
      ) || {}

    if (this.valuationRequest.typeOfProperty) {
      this.selectedPropertyType =
        this.propertyType.find(
          (item) => (item.id = this.valuationRequest.typeOfProperty)
        ) || {}
    }

    if (this.valuationRequest.vrLevel) {
      this.selectedStorey =
        this.storeies.find(
          (item) => (item.id = this.valuationRequest.vrLevel || -1)
        ) || {}
    }

    if (this.valuationRequest.position) {
      this.selectedPropertyPosition =
        this.propertyPositions.find(
          (item) => (item.id = this.valuationRequest.position || -1)
        ) || {}
    }

    if (this.valuationRequest.tenure) {
      this.selectedTenure =
        this.tenures.find(
          (item) => (item.id = this.valuationRequest.tenure || -1)
        ) || {}
    }

    if (this.valuationRequest.bumiStatus) {
      this.selectedBumiStatus =
        this.bumiStatus.find(
          (item) => (item.id = this.valuationRequest.bumiStatus || 100)
        ) || {}
    }
  }

  private bntSaveLoading = false

  async onSaveClick() {
    this.bntSaveLoading = true
    try {
      await new RestValuationRequest().updatePropertyDetail({
        id: this.id,
        address: this.valuationRequestForm.address,
        area: this.valuationRequestForm.area,
        stateTypeId: this.selectedState.id,
        typeOfProperty: this.selectedPropertyType.id,
        vrLevel: this.selectedStorey.id,
        position: this.selectedPropertyPosition.id,
        landAreaSqft: this.valuationRequestForm.landAreaSqft,
        builtupAreaSqft: this.valuationRequestForm.builtupAreaSqft,
        tenure: this.selectedTenure.id,
        tenureExpiryDateV: this.valuationRequestForm.tenureExpiryDateV,
        bumiStatus: this.selectedBumiStatus.id,
        slaDays: this.valuationRequestForm.slaDays,
      })

      this.toastSuccessMsg("Property Detail Update Successfully")
    } catch (e) {
      this.toastExceptionMsg(e)
    }

    this.bntSaveLoading = false
  }
}
