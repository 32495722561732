
































































































































































import BaseLayout from "@/components/BaseLayout.vue"
import { Component, Prop } from "vue-property-decorator"
import CustomerDetail from "./component/CustomerDetail.vue"
import PropertyDetail from "./component/PropertyDetail.vue"
import IndicationDetail from "./component/IndicationDetail.vue"
import RestValuationRequest, {
  RequestType,
  Valuer,
} from "@/rest/RestValuationRequest"
import RestRequestorBranch from "@/rest/RestRequestorBranch"
import ValuationRequest from "@/models/ValuationRequest"
import { RequestorBranch } from "@/models/RequestorBranch"
import State from "@/models/State"
import RestState from "@/rest/RestState"
import PropertyType from "@/models/PropertyType"
import RestProperty from "@/rest/RestProperty"

@Component({
  components: { CustomerDetail, PropertyDetail, IndicationDetail },
})
export default class CaseDetailView extends BaseLayout {
  @Prop() readonly id!: number
  private indication = [
    { text: "Date", value: "date", sortable: false },
    { text: "Address", value: "address", sortable: false },
    { text: "Type", value: "type", sortable: false },
    { text: "Sale", value: "sale", sortable: false },
    { text: "Rate/BU", value: "bu", sortable: false },
    { text: "Rate/LA", value: "la", sortable: false },
    { text: "Built", value: "built", sortable: false },
    { text: "Land", value: "land", sortable: false },
  ]

  private valuationRequest: Partial<ValuationRequest> = {}
  private branches: RequestorBranch[] = []
  private requestType: RequestType[] = []
  private valuerList: Valuer[] = []
  private requestorName = ""
  private selectedBranch: Partial<RequestorBranch> = {}
  private btnRequestorSaveLoading = false
  private states: State[] = []
  private propertyType: PropertyType[] = []
  private selectedPrimaryValuer: Partial<Valuer> = {}
  private selectedSupervisingValuer: Partial<Valuer> = {}
  private selectedAuthorisingValuer: Partial<Valuer> = {}
  private btnValuerSaveLoading = false
  private compareableProperties = []

  mounted() {
    this.loadData()
  }

  async onRequestorSave() {
    this.btnRequestorSaveLoading = true
    try {
      const resp = await new RestValuationRequest().updateRequestor({
        id: this.id,
        branchId: this.selectedBranch.id,
        requestorName: this.requestorName,
      })
      this.toastSuccessMsg("Requestor Detail Save Successfully")
    } catch (e) {
      this.toastExceptionMsg(e)
    }

    this.btnRequestorSaveLoading = false
  }

  async onBtnValuerSaveClick() {
    this.btnValuerSaveLoading = true
    try {
      await new RestValuationRequest().updateValuer({})
      this.toastSuccessMsg("Valuer Update Successfully")
    } catch (e) {
      this.toastExceptionMsg(e)
    }
    this.btnValuerSaveLoading = false
  }

  async loadData() {
    this.loading = true

    try {
      const resp = await new RestValuationRequest().valuer()
      this.valuerList = resp.data
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestValuationRequest().requestType()
      this.requestType = resp.data
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestProperty().type()
      this.propertyType = resp.data
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestRequestorBranch().list(1)
      this.branches = resp.data.content
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestState().list()
      this.states = resp.data
    } catch (e) {
      this.toastErrorMsg(e)
    }

    try {
      const resp = await new RestValuationRequest().detail(this.id)
      this.valuationRequest = resp.data
      this.requestorName = this.valuationRequest.requestorName || ""
      this.selectedBranch =
        this.branches.find(
          (item) => item.id == this.valuationRequest.requestorBranchSelectId
        ) || {}
    } catch (e) {
      this.toastErrorMsg(e)
    }

    this.loading = false
  }
}
