









































































import BaseLayout from "@/components/BaseLayout.vue"
import { Component, Prop } from "vue-property-decorator"
import PropertyInfo from "@/models/PropertyInfo"

@Component({
  components: { BaseLayout },
})
export default class IndicationDetail extends BaseLayout {

}
