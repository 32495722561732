var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{attrs:{"loadingScreen":_vm.loading,"snackbarMsg":_vm.snackbarMessage}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"md":"4"}},[_c('h4',[_vm._v("Requestor Details")]),_c('v-select',{attrs:{"outlined":"","dense":"","clearable":"","label":"Branch","items":_vm.branches},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name + ", " + item.org.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name + ", " + item.org.name))])]}}]),model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}})],1),_c('v-col',{staticClass:"pb-0 mt-6",attrs:{"md":"8"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","clearable":"","label":"Name"},model:{value:(_vm.requestorName),callback:function ($$v) {_vm.requestorName=$$v},expression:"requestorName"}})],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.btnRequestorSaveLoading},on:{"click":_vm.onRequestorSave}},[_vm._v("Save")])],1)],1),_c('v-divider')],1),_c('CustomerDetail',{attrs:{"id":_vm.id,"requesteTypes":_vm.requestType,"valuationRequest":_vm.valuationRequest}}),_c('PropertyDetail',{attrs:{"valuationRequest":_vm.valuationRequest,"states":_vm.states,"propertyType":_vm.propertyType,"id":_vm.id}}),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"md":"4"}},[_c('h4',[_vm._v("Assignee Valuer")]),_c('v-select',{attrs:{"items":_vm.valuerList,"outlined":"","dense":"","clearable":"","label":"Primary Valuer"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedPrimaryValuer),callback:function ($$v) {_vm.selectedPrimaryValuer=$$v},expression:"selectedPrimaryValuer"}})],1),_c('v-col',{staticClass:"pb-0 mt-6",attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.valuerList,"outlined":"","dense":"","clearable":"","label":"Supervising Valuer"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedSupervisingValuer),callback:function ($$v) {_vm.selectedSupervisingValuer=$$v},expression:"selectedSupervisingValuer"}})],1),_c('v-col',{staticClass:"pb-0 mt-6",attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.valuerList,"outlined":"","dense":"","clearable":"","label":"Authorising Valuer"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedAuthorisingValuer),callback:function ($$v) {_vm.selectedAuthorisingValuer=$$v},expression:"selectedAuthorisingValuer"}})],1)],1),_c('v-row',{staticClass:"mb-2",attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"color":"primary","loading":_vm.btnValuerSaveLoading},on:{"click":_vm.onBtnValuerSaveClick}},[_vm._v("Save")])],1)],1),_c('v-divider')],1),_c('IndicationDetail'),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"md":"12"}},[_c('h4',[_vm._v("Insert Comparabilities")]),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v("INSERT COMPARABLE PROPERTIES")]),_c('v-data-table',{attrs:{"headers":_vm.indication,"items":_vm.compareableProperties},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.date))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.address))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.type))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.sale))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.bu))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.la))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.built))]),_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(props.item.land))])]}}])})],1)],1),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }