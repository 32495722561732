var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseLayout',{staticClass:"ma-2",attrs:{"snackbarMsg":_vm.snackbarMessage}},[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('h4',[_vm._v("Property Details")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"5"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Address Line 1"},model:{value:(_vm.valuationRequestForm.address),callback:function ($$v) {_vm.$set(_vm.valuationRequestForm, "address", $$v)},expression:"valuationRequestForm.address"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Address Line 2"},model:{value:(_vm.valuationRequestForm.area),callback:function ($$v) {_vm.$set(_vm.valuationRequestForm, "area", $$v)},expression:"valuationRequestForm.area"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":_vm.states,"outlined":"","dense":"","clearable":"","label":"State","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedState),callback:function ($$v) {_vm.selectedState=$$v},expression:"selectedState"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"4"}},[_c('v-select',{attrs:{"items":_vm.propertyType,"outlined":"","dense":"","clearable":"","label":"Property Type","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedPropertyType),callback:function ($$v) {_vm.selectedPropertyType=$$v},expression:"selectedPropertyType"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"4"}},[_c('v-select',{attrs:{"outlined":"","dense":"","clearable":"","label":"Storey","items":_vm.storeies},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}}]),model:{value:(_vm.selectedStorey),callback:function ($$v) {_vm.selectedStorey=$$v},expression:"selectedStorey"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"4"}},[_c('v-select',{attrs:{"outlined":"","dense":"","clearable":"","label":"Position","items":_vm.propertyPositions},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}}]),model:{value:(_vm.selectedPropertyPosition),callback:function ($$v) {_vm.selectedPropertyPosition=$$v},expression:"selectedPropertyPosition"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Land Area"},model:{value:(_vm.valuationRequestForm.landAreaSqft),callback:function ($$v) {_vm.$set(_vm.valuationRequestForm, "landAreaSqft", $$v)},expression:"valuationRequestForm.landAreaSqft"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Build Up Area"},model:{value:(_vm.valuationRequestForm.builtupAreaSqft),callback:function ($$v) {_vm.$set(_vm.valuationRequestForm, "builtupAreaSqft", $$v)},expression:"valuationRequestForm.builtupAreaSqft"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","clearable":"","label":"Tenure","items":_vm.tenures},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}}]),model:{value:(_vm.selectedTenure),callback:function ($$v) {_vm.selectedTenure=$$v},expression:"selectedTenure"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Tenure Date"},model:{value:(_vm.valuationRequestForm.tenureExpiryDateV),callback:function ($$v) {_vm.$set(_vm.valuationRequestForm, "tenureExpiryDateV", $$v)},expression:"valuationRequestForm.tenureExpiryDateV"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-select',{attrs:{"items":_vm.bumiStatus,"outlined":"","dense":"","clearable":"","label":"Bumi Status"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.desc))])]}}]),model:{value:(_vm.selectedBumiStatus),callback:function ($$v) {_vm.selectedBumiStatus=$$v},expression:"selectedBumiStatus"}})],1),_c('v-col',{staticClass:"pt-1 pb-0",attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"SLA Days"},model:{value:(_vm.valuationRequestForm.slaDays),callback:function ($$v) {_vm.$set(_vm.valuationRequestForm, "slaDays", $$v)},expression:"valuationRequestForm.slaDays"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"depressed":"","color":"error","loading":_vm.bntSaveLoading},on:{"click":_vm.onSaveClick}},[_vm._v("Save")])],1)],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }